<template>
  <div class="vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 m-0 mb-4">
      <vx-card class="login-card py-3 px-3 mb-4">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="px-6 pt-8 login-tabs-container">
              <div class="vx-card__title mb-4">
                <h4 class="mb-4">Verificación de identidad.</h4>
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <vx-card v-if="isMounted && isValid" class="login-card py-3 px-3 mb-4">
        <div class="vx-row m-0">
          <applicant-identity-verification
            ref="applicant-identity-verification"
            :isMoral="false"
            :userId="cuerrentUserId"
            :person-type="0"
            :isPublic="true"
            />
        </div>
      </vx-card>

      <vx-card v-else class="login-card py-3 px-3 mb-4">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="px-6 pt-8 login-tabs-container">
              <div class="vx-card__title mb-4">
                <h4 class="mb-4">
                  Parece que ya ha pasado mucho tiempo desde que solicitamos la verificación de identidad,
                  comunicate con el equipo de RedGirasol a través del chat para que podamos ayudarte a realizar el proceso.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
  </template>
  
  <script>
  import MatiPopup from "@components/mati/MatiPopup";
  import FormsContainer from "@components/profile_shared/FormsContainer";
  import ApplicantIdentityVerification from "@applicant/widgets/ApplicantIdentityVerification.vue";
  import userIdentityHelper from "@/helpers/userIdentityHelper";
  import applicantInfoHelper from "@/helpers/applicantInfoHelper";
  export default {
    name: "AboutYou",
    components: {
      MatiPopup,
      FormsContainer,
      ApplicantIdentityVerification
    },
    props: {
      client_id: { type: [String, Number], required: true },
    },
    mixins: [userIdentityHelper,applicantInfoHelper],
    data() {
      return {
        isMounted: false,
        identity: null,
        status: null,
        processWasStarted: false,
        identityIsCompleted: false,
        isMoral: null,
        mainComplianceStatus: null,
        cuerrentUserId: null,
        isValid: false
      }
    },
    async mounted(){
      await this.checkForValidity();
    },
    computed: {

    },
    methods: {
      async checkForValidity() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const authId = urlParams.get('id')
        const payload = {
          auth_token : authId,
          client_id : this.$props.client_id
        }
        try {
          this.showLoading(true);
          const res = await this.publicApiPost(`/api/v2/applicant/auth/identity-verification`, payload);
          if(res.data){
            this.cuerrentUserId = res.data.user_id;
            this.isValid = res.data.is_valid;
            this.isMounted = true;
          }
          this.showLoading(false);
        } catch (error) {
          this.showLoading(false);
          console.log(error);
        }
      },
      async onMatiProcessStarted(){
        this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
        this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
        this.processWasStarted = true;
      },
      async openMatiPopup(){
        this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
        this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
      },
      async getIdentityInfo(){
        this.status = await this.getUserIdentityStatus(this.UserId);
        this.identityIsCompleted = this.verificationIsCompleted(this.status);
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>