<template>
    <div>
      <div v-if="mainComplianceStatus === 'pending' && isMounted">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mb-4">
              <div class="flex flex-wrap items-center">
                <p
                  class="card-sub-title mr-5"
                  v-if="mverficationNoSuccessful && !processWasStarted"
                >
                  La verificación de identidad no fue exitosa.
                </p>
              </div>
            </div>
  
            <template v-if="!processWasStarted">
              <p v-if="mProcessIsPending">
                En la Red, es importante verificar que tus datos concuerden con tu
                identidad. Es por esto que utilizamos un software de
                reconocimiento de identidad a través de un proceso rápido y
                sencillo. Para iniciar con el proceso da clic en “Comenzar” y
                después lee con calma las indicaciones que tenemos para ti con el
                fin de que tu proceso sea exitoso.
              </p>
              <p v-if="mverficationNoSuccessful">
                <span class="bold"
                  >El proceso de verificación de identidad no fue
                  exitoso.</span
                >
                Comienza nuevamente siguiendo las recomendaciones al pie de la letra
                y asegúrate de concluir el proceso correctamente. Principalmente
                cuida que tengas buena iluminación al momento de grabar tu rostro o
                que las fotografías de tus documentos sean legibles, es decir, que
                no se vean borrosas o con reflejos que no nos permitan leer la
                información claramente.
              </p>
            </template>
            <template v-else>
              <p>
                En la Red, es importante verificar que tus datos concuerden con tu
                identidad. Es por esto que utilizamos un software de
                reconocimiento de identidad a través de un proceso rápido y
                sencillo. Para iniciar con el proceso da clic en “Comenzar” y
                después lee con calma las indicaciones que tenemos para ti con el
                fin de que tu proceso sea exitoso.
              </p>
            </template>
          </div>
        </div>
  
        <div class="vx-row mt-3" v-if="isMounted && processWasStarted">
          <div class="vx-col w-full">
            <vs-alert color="success">
              <span class="bold"
                >Estamos procesando tu información. En breve tendrás una respuesta de parte de RedGirasol.</span
              >
            </vs-alert>
          </div>
        </div>
  
        <div class="vx-row mt-3" v-if="isMounted && !processWasStarted">
          <div class="vx-col w-full" v-if="mshouldRunMatiProcess">
            <vs-button
              class="ml-auto mt-2"
              @click="openMatiPopup"
              v-if="mverficationNoSuccessful"
              >Comenzar nuevamente</vs-button
            >
            <vs-button class="ml-auto mt-2" @click="openMatiPopup" v-else
              >Comenzar</vs-button
            >
          </div>
        </div>
        <mati-popup :user-id-to-activate="userId" 
          @on-mati-process-started="onMatiProcessStarted" />
      </div>
      <div v-else-if="mainComplianceStatus === 'running'">
        <vx-card card-background="#3b3a3d" title="El proceso de verificacion de identidad se encuentra en proceso." title-color="white">
          <p class="text-white">
            El instalador ha iniciado el proceso, y éste se encuentra en proceso de revisión.
          </p>
        </vx-card>
      </div>
      <div v-else-if="mainComplianceStatus === 'completed'">
        <vx-card card-background="#3b3a3d" title="El proceso de verificación de identidad ha sido completado." title-color="white" >
          <p class="text-white">
            La cuenta del instalador ha sido verificada.
          </p>
        </vx-card>
      </div>
    </div>
  </template>
  
  <script>
  import MatiPopup from "@components/mati/MatiPopup";
  import userIdentityHelper from "@/helpers/userIdentityHelper";
  
  export default {
    name: "applicant-identity-verification",
    props: {
      isMoral: { type: Boolean, required: true },
      userId: { type: [String, Number], required: true },
      personType: { type: [String, Number], required: true },
      isPublic: { type: Boolean, required: false, default: false }
    },
    components: {
      MatiPopup,
    },
    mixins: [userIdentityHelper],
    data() {
      return {
        isMounted: false,
        identity: null,
        status: null,
        processWasStarted: false,
        pfaeTabs: ["general"],
        moralTabs: ["general", "contact"],
  
        identityIsCompleted: false,
        mainComplianceStatus: null,
      };
    },
      async mounted() {
          this.isMounted = false;
          this.showLoading(true);
          if(!this.isPublic) {
            await this.onUserInfoUpdated();
          }
          await this.getIdentityInfo();
          await this.verifyCompliances();
          this.isMounted = true;
          this.showLoading(false);
      },
      computed: {
          mProcessIsPending() {
              return this.matiIsPending(this.status);
          },
          mshouldRunMatiProcess() {
              return this.shouldRunMatiProcess(this.status);
          },
          mverificationIsCompleted() {
              return this.verificationIsCompleted(this.status);
          },
          mverificationIsRejected() {
              return this.verificationIsRejected(this.status);
          },
          mverificationIsInProcess() {
              return this.verificationIsInProcess(this.status);
          },
          mverificationisNewRequired() {
              return this.verificationisNewRequired(this.status);
          },
          mverificationIsReviewNeeded() {
              return this.verificationIsReviewNeeded(this.status);
          },
          mverficationNoSuccessful() {
              return (
                  this.mverificationIsRejected ||
                  this.mverificationisNewRequired ||
                  this.mverificationIsReviewNeeded
              );
          },
      },
      methods: {
          async reloadStatus() {
              this.showLoading(true);
              await this.getIdentityInfo();
              this.processWasStarted = false;
              this.showLoading(false);
          },
          async verifyCompliances() {
              // definir si mati esta pendiente o debe hacerse de nueva cuenta
              const shouldRunMati = this.shouldRunMatiProcess(this.status);
              if (shouldRunMati) {
                  this.mainComplianceStatus = "pending";
                  return;
              }
  
              const matiIsRunning = this.matiIsRunning(this.status);
              if (matiIsRunning) {
                  this.mainComplianceStatus = "running";
                  return;
              }
  
              // definir si esta completado
              const matiCompleted = this.verificationIsCompleted(this.status);
              if (matiCompleted) {
                this.mainComplianceStatus = "completed";
              } else {
                this.mainComplianceStatus = "running";
              }
          },
          async onMatiProcessStarted() {
              this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
              this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
              this.processWasStarted = true;
          },
          async openMatiPopup() {
            this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
            this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
          },
          async getIdentityInfo() {
            this.status = await this.getUserIdentityStatus(this.userId);
            this.identityIsCompleted = this.verificationIsCompleted(this.status);
          },
      },
  };
  </script>
  
  <style>
  </style>